<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import { ref, onMounted } from 'vue';
import L from 'leaflet';
import 'leaflet.heat';
import { reqMethods } from '../../../state/helpers';
import axios from "axios";

/**
 * openstreet-map component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  name: 'Heatmap',
  data() {
    return {
      map: null,
      heatmapLayer: null,
      treeData: [],
      incidents: {},
      filterIncidents: {},
      inciTypes: [],
      incidenType: null,
      title: "Cartographie des incidents",
      items: [
        {
          text: "Maps",
          href: "/",
        },
        {
          text: "Incidents",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...reqMethods,
    async filter(inciType) {
      this.incidenType = inciType;

      this.filterIncidents = await this.incidents.filter((incident) => {
        return (
          incident.typInci.includes(inciType)
        );
      });

      this.map.removeLayer(this.heatmapLayer);

      this.heatmapLayer = L.heatLayer(
        this.filterIncidents.map(point => [point.lat, point.lng, 10]),
        { radius: 20, maxZoom: 18 }
      ).addTo(this.map);

    },
    getIncidentType() {
      const that = this;
      this.getRequest('/investigation/type-incident/')
        .then(
          function (response) {
            response.data.forEach(element => {
              that.inciTypes.push({ value: element.id, label: element.name });
            });
          },
          function (error) {
            that.reqError = error;
          }
        )
    },
    async getAllIncidents() {
      const that = this;
      try {
        const response = await axios.get('https://api.alcit.sims-technologie.com/api/v1/investigation/incident/',
          {
            headers: {
              'Authorization': localStorage.getItem('token'),
            },
          });
        that.incidents = response.data.map(
          (incident) => {
            return {
              id: incident.id,
              lat: incident.location?.coordinates[0],
              lng: incident.location?.coordinates[1],
              typInci: incident.type?.name
            };
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.map = L.map('map').setView([6.1319, 1.2225], 12);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: ''
    }).addTo(this.map);

    await this.getAllIncidents();

    this.heatmapLayer = L.heatLayer(
      this.incidents.map(point => [point.lat, point.lng, 10]),
      { radius: 20, maxZoom: 18 }
    ).addTo(this.map);

    this.getIncidentType();
  },
};
</script>

<style>
#map {
  height: 600px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="container bg-white">
      <div class="row">
        <div class="col-lg-6 mt-4">
          <h5 class="d-inline">
            Type incident:
          </h5>
          <p v-if="incidenType == null" class="text-info d-inline">
            Aucun
          </p>
          <p class="text-info d-inline">
            {{ incidenType }}
          </p>
        </div>
        <div class="col-lg-6 mt-4">
          <b-dropdown split text="Filtrer incidents" variant="info" class="float-end mb-3">
            <b-dropdown-item v-for="incident in inciTypes" :key="incident.id" @click="filter(incident.label)"> {{
              incident.label }} </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div id="map"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
